import React  from 'react';
import classnames from 'classnames/bind';

import styles from './main.scss';
let cx = classnames.bind(styles);

import TopNavigationComponent from 'components/TopNavigationComponent';
import ItemsComponent from 'components/ItemsComponent';
import CategoriesComponent from 'components/CategoriesComponent';
import SelectionsComponent from 'components/SelectionsComponent';

const MainRoute = ({ ...prop }) => {

    const items = Object.values(window.data.items)
        .filter(item => item.is_available)
        .filter(item => item.photo_uploaded)
        .filter(item => new Date(item.featured_at).setHours(0,0,0,0) <= new Date().setHours(0,0,0,0))
        .sort((l, r) => new Date(r.featured_at).setHours(0,0,0,0) - new Date(l.featured_at).setHours(0,0,0,0));

    const featuredSelectionSlug = items[0].selection_slug;

    const categories = Object.values(window.data.categories)
        .filter(category => category.is_available)
        .sort((l, r) => l.sorting_index - r.sorting_index);

    const selections = Object.values(window.data.selections)
        .filter(selection => selection.is_available)
        .sort((l, r) => {
            if (l.slug === featuredSelectionSlug && r.slug !== featuredSelectionSlug) {
                return -1;
            } else if (l.slug !== featuredSelectionSlug && r.slug === featuredSelectionSlug) {
                return 1;
            } else {
                return l.id - r.id;
            }
        });

    const modalWindowStyle = { position: 'fixed', width: '100vw', height: '100vh' };

    return (
        <div style={prop.isModal ? modalWindowStyle : {}} id='main'>
            <div className={cx('background')} style={{ backgroundColor: prop.isModal ? 'transparent' : 'transparent'}}></div>
            <div>
                <TopNavigationComponent />
                <div style={{ paddingTop: '20px' }}>
                    <h1 className={cx('section-title')}>Categories</h1>
                    <CategoriesComponent categories={categories} />
                    <h1 className={cx('section-title')}>Selections</h1>
                    <SelectionsComponent selections={selections} />
                    <h1 className={cx('section-title')}>Last drops</h1>
                    <ItemsComponent items={items} shouldScroll={prop.isModal} />
                </div>
            </div>
        </div>
    );
};

export default MainRoute;
