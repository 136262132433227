import React from 'react';
import ReactDOM from "react-dom/client";
import * as amplitude from '@amplitude/analytics-browser';

import App from './App';

amplitude.init("d4c9b6a2ab66bb273a6ef201f2e4e8ad");

const app = ReactDOM.createRoot(document.getElementById("app"));

app.render(<App />);