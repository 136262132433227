import React, { useEffect, useState } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { useNProgress } from '@tanem/react-nprogress'
import classnames from 'classnames/bind';

import styles from 'styles/global.scss';
let cx = classnames.bind(styles);

import MainRoute from 'routes/MainRoute';
import SelectionRoute from 'routes/SelectionRoute';
import CategoryRoute from 'routes/CategoryRoute';

import BarComponent from 'components/BarComponent';

const Container = ({ animationDuration, children, isFinished }) => (
    <div
      style={{
        opacity: isFinished ? 0 : 1,
        pointerEvents: 'none',
        transition: `opacity ${animationDuration}ms ease-out`,
      }}
    >
      {children}
    </div>
);

const Progress = ({
    animationDuration,
    incrementDuration,
    isAnimating,
    minimum
  }) => {
    const { isFinished, progress } = useNProgress({
        animationDuration,
        incrementDuration,
        isAnimating,
        minimum
    })

    return (
        <Container animationDuration={animationDuration} isFinished={isFinished}>
            <BarComponent animationDuration={animationDuration} progress={progress} />
        </Container>
    )
};

const Background = ({ path }) => {
    const type = path[0];
    const slug = path[1];

    if (type === '') 
        return <MainRoute isModal />;
    if (type === 'selection') 
        return <SelectionRoute slug={slug} isModal />;
    if (type === 'category') 
        return <CategoryRoute slug={slug} isModal />;

    return null;
};

const HomeRoute = () => {

    const location = useLocation();
    const [isAnimating, setIsAnimating] = useState(true);

    useEffect(() => {
        setIsAnimating(true);

        setTimeout(() => {
            setIsAnimating(false);
        }, 300);
    }, [location])

    const pathname = location.state ? location.state.backgroundLocation.pathname : null;

    return (location.state && location.state.backgroundLocation) ? (
        <main>
            <div className={cx('background')}></div>
            {<Background path={(pathname.endsWith('/') ? pathname.slice(0, -1) : pathname).split('/').slice(-2)} />}
            <Outlet />
        </main>
        ) : (
        <main>
            <Progress 
                animationDuration={300}
                isAnimating={isAnimating}
                key={location.key}
            />
            <div className={cx('background')}></div>
            <Outlet />
        </main>
    );
};

export default HomeRoute;
