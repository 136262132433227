import React from 'react';
import classnames from 'classnames/bind';
import * as amplitude from '@amplitude/analytics-browser';

import styles from './download.scss';
import AppStoreButtonComponent from 'components/AppStoreButtonComponent';
let cx = classnames.bind(styles);

const DownloadRoute = () => {

    const translation = window.data.translation;

    return (
        <div className={cx('download', 'flex', 'column', 'justify-content-center', 'align-items-center')}>
            <h1 className={cx('logo')}>
                {translation['title']}
            </h1>
            <img src="/logo.png" alt="logo" className={cx('logo-icon')} />
            <h2 className={cx('description')}>
                {translation['description']}
            </h2>
            <AppStoreButtonComponent />
        </div>
    );
};

export default DownloadRoute;
