import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames/bind';

import styles from './items.scss';
let cx = classnames.bind(styles);

import ItemComponent from 'components/ItemComponent';

const ItemsComponent = ({ items, shouldScroll }) => {

    const location = useLocation();

    const scrollPathName = (location.state) ? location.state.backgroundLocation.pathname : location.pathname;
    const scrollStorageKey = `scroll-position-item-id-marker-${scrollPathName}`;

    const persistScrollPosition = (id) => {
        window.sessionStorage.setItem(scrollStorageKey, id);
    };

    useEffect(() => {
        const persistedId = window.sessionStorage.getItem(scrollStorageKey);

        if (persistedId === null) {
            window.scrollTo(0, 0);
            return;
        }

        const parent = document.getElementById('items');
        const element = parent.children.item(Number(persistedId));
        let main = document.getElementById('main');

        if (element && main) {
            if (location.state) {
                const rect = element.getBoundingClientRect();
                const mainRect = main.getBoundingClientRect();
                
                let offset = rect.y - mainRect.height / 2 + rect.height / 2;;
                main.style.transform = `translateY(-${offset}px)`;
            } else {
                element.scrollIntoView({ behavior: 'auto', block: 'center' });
                window.sessionStorage.removeItem(scrollStorageKey);
            }
        }
    }, []);

    return (
        <div id='items' className={cx('items', 'justify-content-center', 'align-items-center')}>
            {
                items.map((item, i) => {
                    let index = i + 1;
                    let bandageAngle = (index % 4 === 0) ? '-10deg' : (index % 3 === 0) ? '25deg' : (index % 2 === 0) ? '15deg' : '-25deg';

                    const persistedId = window.sessionStorage.getItem(scrollStorageKey);

                    return (
                        <ItemComponent
                            id={i}
                            key={i}
                            onSelect={persistScrollPosition}
                            shouldScroll={persistedId ? (Number(persistedId) === i) : false}
                            item={item} 
                            bandageAngle={bandageAngle}
                        />
                    );
                })
            }
        </div>
    );
};

export default ItemsComponent;
