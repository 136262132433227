import React, { useEffect, useState }  from 'react';
import { useParams } from 'react-router-dom';
import classnames from 'classnames/bind';
import * as amplitude from '@amplitude/analytics-browser';

import styles from './category.scss';
let cx = classnames.bind(styles);

import ItemsComponent from 'components/ItemsComponent';
import TopNavigationComponent from 'components/TopNavigationComponent';

const CategoryRoute = ({ ...prop }) => {

    const params = useParams();
    const slug = prop.slug ? prop.slug : params.slug;
    const [category, setCategory] = useState(null);

    const items = Object.values(window.data.items)
        .filter(item => item.is_available)
        .filter(item => item.photo_uploaded)
        .filter(item => item.category_slug === slug)
        .filter(item => new Date(item.featured_at).setHours(0,0,0,0) <= new Date().setHours(0,0,0,0))
        .sort((l, r) => new Date(r.featured_at).setHours(0,0,0,0) - new Date(l.featured_at).setHours(0,0,0,0));

    useEffect(() => {
        if (!slug) return;

        let category = window.data.categories[slug];
        if (category) {
            setCategory(category);

            // Track category opened
            amplitude.track('Category Opened', { 
                category_id: category.id,
                category_slug: category.slug,
                origin: 'website'
            });
        }
    }, [slug]);

    const modalWindowStyle = { position: 'fixed', width: '100vw', height: '100vh' };

    return (
        <>
            { category && (
                <>
                    <div className={cx('background')} style={{ backgroundColor: prop.isModal ? 'transparent' : 'rgba(0, 0, 0, 0.7)'}}></div>
                    <div style={prop.isModal ? modalWindowStyle : {}} id='main'>
                        <TopNavigationComponent />
                        <div className={cx('flex', 'column', 'justify-content-center', 'align-items-center')}>
                            <img src={`https://storage.googleapis.com/silly-db/icons/${category.slug}.png`} alt={category.title} className={cx('category-image')} />
                            <h1 className={cx('category-title')}>{category.title}</h1>
                            <h2 className={cx('category-description')}>{category.description}</h2>
                        </div>
                        <ItemsComponent items={items} />
                    </div>
                </>
            )}
        </>
    );
};

export default CategoryRoute;
