import React from 'react';
import classnames from 'classnames/bind';

import styles from './selections.scss';
let cx = classnames.bind(styles);

import SelectionComponent from 'components/SelectionComponent';

const SelectionsComponent = ({ selections }) => {

    return (
        <div id='selections' className={cx('selections', 'justify-content-center', 'align-items-center')}>
            {
                selections.map((selection, i) => {
                    return (
                        <SelectionComponent
                            key={i}
                            selection={selection}
                            isFeatured={i == 0}
                        />
                    );
                })
            }
        </div>
    );
};

export default SelectionsComponent;
