import React from 'react';
import classnames from 'classnames/bind';

import styles from './categories.scss';
let cx = classnames.bind(styles);

import CategoryComponent from 'components/CategoryComponent';

const CategoriesComponent = ({ categories }) => {

    return (
        <div id='categories' className={cx('categories', 'justify-content-center', 'align-items-center')}>
            {
                categories.map((category, i) => {
                    return (
                        <CategoryComponent
                            key={i}
                            category={category} 
                        />
                    );
                })
            }
        </div>
    );
};

export default CategoriesComponent;
