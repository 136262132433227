import React from 'react';
import { Link, useParams } from 'react-router-dom';
import classnames from 'classnames/bind';

import styles from './category.scss';
let cx = classnames.bind(styles);

const CategoryComponent = (props) => {

    const { category } = props;
    const params = useParams();

    return category && (
        <Link className={cx('category')} to={`/${params.locale}/category/${category.slug}`}>
            <div className={cx('flex', 'row', 'align-items-center', 'justify-content-center')} style={{ height: '100%', width: '90%', margin: '0 auto' }}>
                <img src={`https://storage.googleapis.com/silly-db/icons/${category.slug}.png`} alt={category.title} className={cx('category-image')} />
                <p className={cx('category-title')}>{category.title}</p>
            </div>
        </Link>
    );
};

export default CategoryComponent;
