import React from 'react';
import { Link, useParams } from 'react-router-dom';
import classnames from 'classnames/bind';

import styles from './selection.scss';
let cx = classnames.bind(styles);

const SelectionComponent = (props) => {

    const { selection, isFeatured } = props;
    const params = useParams();

    return selection && (
        <Link className={cx('selection')} to={`/${params.locale}/selection/${selection.slug}`}>
            <div className={cx('flex', 'row', 'align-items-center')}>
                <img src={`https://storage.googleapis.com/silly-db/images/selection-${selection.slug}.jpg`} alt={selection.title} className={cx('selection-image')} />
                <p className={cx('selection-title', 'flex', 'column', 'justify-content-around', 'align-items-center')}>
                    {
                        isFeatured && (
                            <span className={cx('featured')}>FEATURED</span>
                        )
                    }
                    <span className={cx('title', 'flex', 'justify-content-center', 'align-items-center')}>{selection.title}</span>
                </p>
            </div>
        </Link>
    );
};

export default SelectionComponent;
