import React from 'react';
import { 
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements
} from 'react-router-dom';

import HomeRoute from 'routes/HomeRoute';
import ItemRoute from 'routes/ItemRoute';
import CategoryRoute from 'routes/CategoryRoute';
import SelectionRoute from 'routes/SelectionRoute';
import DownloadRoute from 'routes/DownloadRoute';
import NoMatchRoute from 'routes/NoMatchRoute';
import MainRoute from './MainRoute';

const NavigationRoutes = () => {
    const RoutesJSX = (
        <Route path="/:locale" element={<HomeRoute />}>
            <Route path="/:locale" element={<MainRoute />} />
            <Route path="/:locale/download" element={<DownloadRoute />} />
            <Route path="/:locale/category/:slug" element={<CategoryRoute />} />
            <Route path="/:locale/selection/:slug" element={<SelectionRoute />} />
            <Route path="/:locale/item/:slug" element={<ItemRoute />} />
            <Route path="*" element={<NoMatchRoute />} />
        </Route>
    );

    const routes = createRoutesFromElements(RoutesJSX);
    const router = createBrowserRouter(routes);

    return <RouterProvider router={router} />;
};

export default NavigationRoutes;
