import React from 'react';
import { Link, useParams } from 'react-router-dom';
import classnames from 'classnames/bind';
import AppStoreButtonComponent from 'components/AppStoreButtonComponent';

import styles from './nav.scss';
let cx = classnames.bind(styles);

const TopNavigationComponent = () => {

    const params = useParams();

    return (
        <nav>
            <Link className={cx('brand')} to={`/${params.locale}`}>
                <div className={cx('flex', 'column')}>
                    <div className={cx('flex', 'row', 'align-items-center')}>
                        <div className={cx('logo')}>$ILLY<span style={{ color: 'var(--yellow)'}}>.CASH</span></div>
                        <img src="/logo.png" alt="logo"  className={cx('logo-icon')} />
                    </div>
                    <div className={cx('logo-description')}>
                        the silliest of the internets
                    </div>
                    <div className={cx('logo-copyright')}>
                        <div className={cx('logo-copyright-icon')}>©</div> SILLY LABS 2023
                    </div>
                </div>
            </Link>
            <div className={cx('appstore', 'flex', 'justify-content-end')} >
                <AppStoreButtonComponent />
            </div>
        </nav>
    );
};

export default TopNavigationComponent;
