import React from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import classnames from 'classnames/bind';

import styles from './item.scss';
let cx = classnames.bind(styles);

const ItemComponent = (props) => {

    const { item, id, onSelect, shouldScroll, bandageAngle } = props;
    const params = useParams();
    const location = useLocation();

    const isToday = (dateToCheck) => {
        const today = new Date();

        const isSameDate =
            dateToCheck.getDate() === today.getDate() &&
            dateToCheck.getMonth() === today.getMonth() &&
            dateToCheck.getFullYear() === today.getFullYear();

        return isSameDate;
    };      

    return item && (
        <Link id={`item-${id}`} className={cx('item')} to={`/${params.locale}/item/${item.slug}`} state={{ backgroundLocation: location }} onClick={() => onSelect(id)}>
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAB+CAYAAACJZymYAAAACXBIWXMAACE4AAAhOAFFljFgAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALnSURBVHgB7d2xbtpQFMbxk7Yz5AFA9QMwdGbiETogGFlgpogHQDwAgm5dgHRHSvsE3Vi7sJeKB0jL1I36c3xTJ6Lknut7zQk6f+kIQiD2z8Ymk7mif13H04/nXXp/G8/XeL7QC6sTz108hyPzLZ6I5HSdztEiOo7Izo9Tf6CAsOwRPd7gWKf+0yfe0vMYzHc6Dyii+xU/taEj8+Q7ssOcAxTRaUj2UEg6MKcoUER2EDMNIj4Gs6SwRUwIZuaKCQmKHCAP63MgOSB8xnGOYa8YzJT8lAfiDYMZUb4aOSFeMXlAHU/L94pxAfmCBMFwQH3Pyw2CsQGNAixzGQqD+VAgJDgG0ykIUggmCxoFXs7SGlMqlZJxXNAt8V/Dxrwiy8rlMq1WK4pB5NB7KiBrDKrVanlArKrVKk2nvP+UWBgE0GKxoJABgo2GW05sDKrX6+ytZpvZ+1wIekOOtdvt5HYwGJCv8r6NnfaMCaDxeEw+8nE85sKgXq9Hw+GQ8tRqtbycWJzfZtkMZjKZEDdAZrMZ+Sj3njEBxN1DPiHIGwZxQHieTwjyikE2IJe9aJOXY+ZpWNFKpULz+Zw2m83D4zhjdbvd5O0VoiAYhNM2Zr/fJyB8CLp8EHIKhjHhdIv/GIrI+zFzzhQjNcVITTFSU4zUFCM1xUhNMVJTjNQUIzXFSE0xUlOM1BQjNcVITTFSU4zUFCM1xUhNMVJTjNQUIzXFSE0xUlOM1BQjNcVITTFSU4zUFCM1xUhNMVJTjNQUIzXFSE0xUlOM1BQjNcVITTFSuygM6+IG6/U6uZqWuT5M6CsvcLPG7HY7ajab//09gICiLDL7OK6rYTL3sxsn+1rzGk5XdH+hs0voRk8AUrs4zJYuJGA+04UEDK7+tKWX329zJyK3S3FLmsbrFPMrno/x/Ex//pMOHj/nlxvYhnX/dMV7zaOvfogsHn975PfZ55z8KgnLAEku2svFhC5Kb23weNfcpLdJfwEN2N6VEKocdwAAAABJRU5ErkJggg==" alt="bandage" className={cx('bandage')} style={{ transform: `rotate(${bandageAngle})` }} />
            
            {
                !isToday(new Date(item.featured_at)) &&
                <div className={cx('item-rating')}>
                    {item.ratings_count}
                    <svg className={cx('icon')} width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <g><path d="M3.83388 3.88804C4.17724 3.37742 4.32135 2.84142 4.59761 2.22486C4.80248 1.72915 5.06959 1.26132 5.39255 0.832578C5.68937 0.437045 6.19605 0.62086 6.45744 0.944876C6.61521 1.15907 6.74256 1.39395 6.83587 1.64286L7.68244 3.60803C7.83005 3.95061 7.8914 4.27459 8.33983 4.28623C8.75778 4.29708 9.20081 4.2841 9.61904 4.28341C9.97342 4.28285 10.2891 4.29465 10.6532 4.30071C10.9301 4.3053 11.2855 4.31446 11.495 4.52907C11.7611 4.80173 11.6313 5.22418 11.4593 5.50747C11.3437 5.66292 11.1984 5.79403 11.0317 5.89325C10.5632 6.23239 9.29508 7.02932 9.15444 7.12637C8.80547 7.36705 8.82235 7.4512 8.95777 7.77467C9.14552 8.22303 9.64277 9.73417 9.72201 10.0289C9.79733 10.3085 9.8467 10.5968 9.90123 10.8799C10.0384 11.5922 9.478 11.8025 9.14321 11.6595C8.78848 11.508 7.67923 10.9908 7.1785 10.71C6.88412 10.5449 6.00974 10.0472 5.86959 9.95984C5.75613 9.88907 5.6309 9.79742 5.49855 9.88636C5.27798 10.0346 3.40934 11.2948 3.03887 11.5122C2.73228 11.6922 2.33883 11.908 1.98677 11.6975C1.77311 11.57 1.77638 11.3586 1.78575 11.128C1.79763 10.8357 2.31087 8.88141 2.4738 8.18454C2.51021 8.02889 2.54551 7.87298 2.57972 7.7168C2.59775 7.62731 2.59088 7.53462 2.55984 7.44875C2.52881 7.36288 2.47479 7.28711 2.40364 7.22964C1.93795 6.73023 1.47357 6.23018 1.02092 5.71893C0.817294 5.48897 0.456902 5.19332 0.470982 4.85517C0.491421 4.36382 0.932189 4.24974 1.32001 4.23852C1.7085 4.22731 2.80288 4.21056 3.19211 4.2135C3.31962 4.23096 3.44944 4.20914 3.56415 4.15097C3.67886 4.0928 3.77298 4.00105 3.83388 3.88804Z" /></g>
                    </svg>
                </div>
            }

            {
                isToday(new Date(item.featured_at)) &&
                <div className={cx('item-new')}>
                    <svg xmlns="http://www.w3.org/2000/svg" className={cx('icon')}>
                        <polygon id="star" points="100,0,118.91427938628672,17.13112746454499,143.38837391175582,9.90311320975809,152.99663315799233,33.544323990217464,178.183148246803,37.65101981412664,176.58235377170564,63.119882175007554,197.49279121818236,77.74790660436855,185,100,197.49279121818236,122.25209339563143,176.58235377170564,136.88011782499245,178.183148246803,162.34898018587336,152.99663315799236,166.45567600978254,143.38837391175582,190.0968867902419,118.91427938628674,182.868872535455,100.00000000000001,200,81.08572061371328,182.868872535455,56.6116260882442,190.0968867902419,47.003366842007665,166.45567600978254,21.816851753197028,162.34898018587336,23.417646228294387,136.88011782499245,2.5072087818176385,122.25209339563146,15,100.00000000000001,2.5072087818176385,77.74790660436858,23.41764622829436,63.119882175007575,21.816851753197014,37.65101981412666,47.00336684200764,33.54432399021748,56.611626088244165,9.90311320975809,81.08572061371325,17.131127464545003"></polygon>
                    </svg>
                    <p>NEW</p>
                </div>
            }
            <img src={`https://storage.googleapis.com/silly-db/images/${item.slug}.png`} alt={item.title} className={cx('item-image')} />
            <p className={cx('item-title')}>{item.title}</p>
        </Link>
    );
};

export default ItemComponent;
